import styled, {css} from 'styled-components';
import { IStyledProps } from '../../models/styledProps';
import { Heading, Text } from '@movember/mo-gel';

interface ILowLevelGraph extends IStyledProps {
    fillerWidth?: number | undefined;
    fillerColor?: string;
    legendColor?: string;
}

const stripedBackground = (props: IStyledProps) => {
    return css`
    background: linear-gradient(135deg,
        ${props.theme.colors.black} 8.33%,
        ${props.theme.colors.white} 8.33%,
        ${props.theme.colors.white} 50%, ${props.theme.colors.black} 50%,
        ${props.theme.colors.black} 58.33%,
        ${props.theme.colors.white} 58.33%,
        ${props.theme.colors.white} 100%);
    background-size: 8.49px 8.49px;
    `
};

export const StyledHeading = styled(Heading)`
    margin-bottom: 0;
    padding-right: 10rem;
    line-height: 2rem;
`;

export const StyledLowLevelGraph = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const GraphContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding-top: 2.3rem;
`;

export const Bar = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    height: 2rem;
    width: 100%;
    border-radius: 5px;
    background: ${(props: IStyledProps) => props.theme.colors.white};
`

export const Filler = styled.div`
    display: flex;
    height: 100%;
    ${(props: ILowLevelGraph) => props.fillerColor === "striped" ? stripedBackground : `background: ${props.fillerColor}`};
    width: ${(props: ILowLevelGraph) => props.fillerWidth}%;
    justify-content: center;

    & > svg {
        width: 19.52px;
        height: 23px;
        z-index: 1;
        align-self: center;
        justify-self: center;
    }
`

export const GraphLabelContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const LeftGraphLabel = styled.div`
    text-align: left;
    color: ${(props: IStyledProps) => props.theme.colors.white};
`

export const RightGraphLabel = styled.div`
    color: ${(props: IStyledProps) => props.theme.colors.white};
    text-align: right;
`


export const LabelScore = styled(Heading)`
    color: ${(props: IStyledProps) => props.theme.colors.white};
    margin-bottom: 0;
`

export const LabelTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: ${(props: IStyledProps) => props.theme.colors.white};
`

export const GraphTitle = styled(Heading)`
    display: flex;
    color: ${(props: IStyledProps) => props.theme.colors.white};
    margin-bottom: 0.8rem;
`;

export const GraphSummaryText = styled(Text)`
    display: flex;
    color: ${(props: IStyledProps) => props.theme.colors.white};
`;

export const LeftLegendContainer = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    text-align: left;
    justify-content: flex-start;
`

export const RightLegendContainer = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    text-align: right;
    justify-content: flex-end;
`

export const LegendSquare = styled.div`
    margin-left: 0.5rem;
    height: 15px;
    width: 15px;
    align-self: center;
    border-radius: 5px;
    ${(props: ILowLevelGraph) => props.legendColor === "striped" ? stripedBackground : `background: ${props.legendColor}`}
`

export const StyledManPlot = styled.div`
    display: flex;
    background: ${(props: IStyledProps) => props.theme.colors.actionPrimary300};
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 50%;
    position: absolute;
    z-index: 2;

    & > svg {
        width: 19.52px;
        height: 23px;
        z-index: 1;
    }
`

export const IconText = styled.div`
    width: 2px;
    height: 30px;
    background-color: ${(props: IStyledProps) => props.theme.colors.actionPrimary300};
    transform: translateY(-100%);
    display: flex;
    justify-content: center;
    position: absolute;
    transform: translateY(-50%);

    & > span {
        position: absolute;
        width: max-content;
        top: 0;
        transform: translateY(-100%);
    }

`