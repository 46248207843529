import React from 'react';
import {
  StyledSignpostOAContainer,
  StyledContentContainer,
  StyledTextContainer
} from './SignpostOA.styled';
import { Heading, Text, Link } from '@movember/mo-gel';

export const SignpostOA: React.FC<any> = () => {
  return (
    <StyledSignpostOAContainer>
      <StyledContentContainer>
        <Heading level="h4" fontWeight="500">
          Not sure about comparing <br/> yourself to other men?
        </Heading>
        <StyledTextContainer>
          <Text tag="p" marginBottom="2.2rem">
          We've got information and resources to help tackle common <br/>
          issues experienced after prostate cancer diagnosis.
          </Text>
        </StyledTextContainer>
        <Link onClick={() =>  window.open('https://truenorth.movember.com', '_blank')}>Explore resources</Link>
      </StyledContentContainer>
    </StyledSignpostOAContainer>
  );

};