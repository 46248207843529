import React from "react";
import { Text } from "@movember/mo-gel";
import { ReactComponent as PersonIcon } from "../../assets/person.svg";
import {
  StyledGraphContainer,
  StyledGraphTitle,
  StyledGraph,
  StyledManPlot,
  StyledOtherMenPlot,
  StyledLabelContainer,
  StyledContainer,
} from "./HighLevelComparisonGraph.styled";

interface IHighLevelComparisonGraph {
  /** Man's score */
  manScore: number;

  /** Score range for other men */
  otherMenScoreStart: number;
  otherMenScoreEnd: number;
}

export const HighLevelComparisonGraph: React.FC<IHighLevelComparisonGraph> = ({
  manScore,
  otherMenScoreStart,
  otherMenScoreEnd,
}) => {
  return (
    <StyledContainer>
      <StyledGraphTitle data-testid="title">Your comparison</StyledGraphTitle>
      <StyledGraphContainer>
        <StyledGraph>
          {manScore && (
            <StyledManPlot manScore={manScore} data-testid="man-plot">
              <PersonIcon data-testid="person-icon" />
              <StyledLabelContainer isManScore={true}>
                <Text level="normal" color="white" data-testid="man-label">
                  You
                </Text>
              </StyledLabelContainer>
            </StyledManPlot>
          )}
          <StyledOtherMenPlot
            otherMenScoreStart={otherMenScoreStart}
            otherMenScoreEnd={otherMenScoreEnd}
            data-testid="other-men-plot"
          >
            <StyledLabelContainer isManScore={false}>
              <Text level="normal" color="white">
                Most men like you
              </Text>
            </StyledLabelContainer>
          </StyledOtherMenPlot>
        </StyledGraph>
      </StyledGraphContainer>
    </StyledContainer>
  );
};
