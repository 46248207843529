export const highLevelComparisons = [
  {
    id: "uin",
    label: "Urinary Incontinence",
    phrase: "Your score of {manScore} relates to your level of urinary control, urine leakage and number of pads worn per day. The higher the score the better your urinary function.",
    slug: "urinary_issues",
    ctaTopic: "urinary issues",
  },
  {
    id: "uir",
    label: "Urinary Irritation",
    phrase: "Your score of {manScore} relates to bleeding, pain or burning when urinating, needing to urinate often and being unable to urinate completely. A higher score means you’re experiencing fewer problems.",
    slug: "urinary_issues",
    ctaTopic: "urinary issues",
  },
  {
    id: "s",
    label: "Sexual Function",
    phrase: "Your score of {manScore} relates to the quality and frequency of erections, orgasms and sexual activity. The higher the score the better your function.",
    slug: "sexual_function",
    ctaTopic: "sexual function",
  },
  {
    id: "LIBIDOQu2",
    label: "Sexual Medications or Devices",
    phrase: "You said {answerLabel} to using any medications or devices to improve erections. {manScore}% of men similar to you are likely to use medications or devices to improve erections.",
    slug: "improving_sexual_function",
    ctaTopic: "erectile medications or devices",
    graphsection_1: {
      answers: [0],
      labels: ['No']
    },
    graphsection_2: {
      answers: [1],
      labels: ['Yes']
    }
  },
  {
    id: "b",
    label: "Bowel Function",
    phrase: "Your score of {manScore} relates to frequency, urgency and control of bowel movements, pain in your bowel region and blood in your stool. The higher the score the better your function.",
    slug: "bowel_function",
    ctaTopic: "bowel issues",
  },
  {
    id: "h",
    label: "Hormonal Issues",
    phrase: "Your score of {manScore} relates to hot flushes, breast tenderness or enlargement, feeling depressed, lacking energy and weight problems. A higher score means you’re experiencing fewer problems.",
    slug: "hormonal_issues",
    ctaTopic: "hormonal issues",
  },
];
