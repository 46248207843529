import styled from 'styled-components';
import { IStyledProps } from '../../models/styledProps';
import { Button, Heading } from '@movember/mo-gel';

export const StyledPageHeaderBackgroundContainer = styled.div`
  background: ${(props: IStyledProps) => props.theme.colors.brandThemeLight100};
  width: 100%;
`;

export const StyledPageHeaderContent = styled.div`
  max-width: ${(props: IStyledProps) => props.theme.breakpoints.lg};
  text-align: center;
  margin: 0 auto;
`;

export const StyledHeading = styled(Heading)`
  text-decoration-color: ${(props: IStyledProps) => props.theme.colors.actionPrimary300};
`;

export const StyledSubHeading = styled(Heading)`
  line-height: 2rem;
`;

export const StyledButton = styled(Button)`
  margin: 0 0.7rem;
  flex-direction: row;
  width: auto;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledImage = styled.img`
  width: 100%;
  padding: 0 2.4rem 2.7rem 2.4rem;
`;

export const StyledImageContainer = styled.div`
  background: linear-gradient(0deg, ${(props: IStyledProps) => props.theme.colors.white} 50%, ${(props: IStyledProps) => props.theme.colors.brandThemeLight100} 50%, ${(props: IStyledProps) => props.theme.colors.brandThemeLight100} 100%) no-repeat;
  `;

export const StyledImageContentContainer = styled.div`
  max-width: ${(props: IStyledProps) => props.theme.breakpoints.lg};
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2.5rem;
  margin: 0 auto;
`;

export const StyledPageHeaderContentContainer = styled.div`
  max-width: ${(props) => props.theme.breakpoints.lg};
  margin: 0 auto;
  padding: 6.2rem 8.5rem 0 8.5rem;
`;