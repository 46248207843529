import styled, { createGlobalStyle } from 'styled-components';
import { IStyledProps } from './models/styledProps';
import { nestedPropExists } from '@movember/mo-gel/utils/validation';
import { normaliseColor } from '@movember/mo-gel/utils/colors';

export const StyledApp = styled.div`
  width: 100%;
  min-width: ${(props: IStyledProps) => props.theme.breakpoints.md};
  background-color: ${(props) => props.theme.dark ? props.theme.colors.brandThemeDark900 : props.theme.colors.white};

  @media print {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
`;

export const StyledSpinnerContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const GlobalStyle = createGlobalStyle`  
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  body {
    padding: 0;
    font-family: ${(props: IStyledProps) => props.theme.defaultFontFamily}, sans-serif;
  }

  :root {
    color: ${(props: any) => {
      return (
        (nestedPropExists(props.theme, 'typography', 'defaultColors') &&
          normaliseColor(props.theme.typography.defaultColors, props.theme)) ||
        (nestedPropExists(props.theme, 'colors') && props.theme.colors.primaryFont)
      );
    }};

    font-size: ${(props: any) =>
      nestedPropExists(props.theme, 'typography', 'baseFontSize', 'xxs') &&
      props.theme.typography.baseFontSize.xxs};
    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.xs}) {
      font-size: ${(props: any) =>
        nestedPropExists(props.theme, 'typography', 'baseFontSize', 'xs') &&
        props.theme.typography.baseFontSize.xs};
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.sm}) {
      font-size: ${(props: any) =>
        nestedPropExists(props.theme, 'typography', 'baseFontSize', 'sm') &&
        props.theme.typography.baseFontSize.sm};
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.md}) {
      font-size: ${(props: any) =>
        nestedPropExists(props.theme, 'typography', 'baseFontSize', 'md') &&
        props.theme.typography.baseFontSize.md};
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.lg}) {
      font-size: ${(props: any) =>
        nestedPropExists(props.theme, 'typography', 'baseFontSize', 'lg') &&
        props.theme.typography.baseFontSize.lg};
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints && props.theme.breakpoints.xl}) {
      font-size: ${(props: any) =>
        nestedPropExists(props.theme, 'typography', 'baseFontSize', 'xl') &&
        props.theme.typography.baseFontSize.xl};
    }
  }
`;
