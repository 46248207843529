import React, { useState, useEffect, useContext } from "react";
import { STORAGE_TYPES } from "../../constants"
import { getCookieValue, storeCookie } from "../../utils/cookie"
import { ThemeContext } from "styled-components";
import DataLayer from "../../services/Datalayer";
import Hotjar from "../../services/Hotjar";

export const Cookies: React.FC = () => {
    const [ cookiesAcceptedAt, setCookiesAcceptedAt ] = useState<null | string>(getCookieValue(STORAGE_TYPES.cookiesAccepted));
    const theme = useContext(ThemeContext);

    const acceptCookies = () => {
            const newCookiesAcceptedAt = new Date().toString() + "|truenorthtracker";
            storeCookie(STORAGE_TYPES.cookiesAccepted, newCookiesAcceptedAt);
            setCookiesAcceptedAt(newCookiesAcceptedAt);
    }

    useEffect(() => {
        if (!cookiesAcceptedAt) {
            acceptCookies();
        }
        else {
            Hotjar.initialize();
            DataLayer.init({ breakpoints: theme.breakpoints });
        }
    }, [cookiesAcceptedAt, theme.breakpoints]);
    
    return (null)
}
