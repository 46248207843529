import React from 'react';
import { HeaderContainer, StyledHeader } from './Header.styled';
import {
    NavMenu,
    NavMenuItem
} from "@movember/mo-gel";


export const Header: React.FC<any> = () => {

    const navLinks = [
        {
            link: "/",
            target: "",
            children: "About BroSupPORT"
        },
        {
            link: "/compare",
            target: "",
            children: "Comparisons"
        }
    ];
    
    
    return (
        window.location.pathname!=="/error" ? 
        (<HeaderContainer>
            <StyledHeader>
                <div />
                <NavMenu>
                {navLinks.map((link, index) => {
                    return (
                        <NavMenuItem
                            key={index}
                            index={index}
                            to={link.link}
                            target={link.target}
                        >
                            {link.children}
                        </NavMenuItem>
                    )
                })}
                </NavMenu>
            </StyledHeader>
        </HeaderContainer>
        ) : (null)
        
    )

}
