import {STORAGE_TYPES} from "../constants";
import { getCookieValue } from "../utils/cookie"

// Copied from TNOA v2, will need to update with events once we know what we want to track
// Will have to update id as well if we are using a seperate dashboard

declare global {
    interface Window {
    dataLayer: any[];
    }
}

const DataLayer = {
    // We store the time the page was loaded so we can use this later
    // to calculate the time spent before initiating an action
    timeLoaded: 0,
    setTmeLoaded() {
    this.timeLoaded = +new Date().getSeconds;
    },

    // Generates a unique ID for the process Id (Copied from the previous TN implementation)
    getProcessId() {
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
    },

    push(data: any) {
        if (getCookieValue(STORAGE_TYPES.cookiesAccepted)) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(data);
        }
    },

    // Initialise the data layer
    init({ breakpoints }: { breakpoints: any; }) {
        // Do nothing if cookie consent is not accepted
        if (!getCookieValue(STORAGE_TYPES.cookiesAccepted)) return;

        const getUiExperience = (viewportWidth: number): string | undefined => {
        return (
            (viewportWidth <= +breakpoints.xs.replace('px', '') && 'mobile') ||
            (viewportWidth <= +breakpoints.sm.replace('px', '') && 'tablet') ||
            (viewportWidth <= +breakpoints.md.replace('px', '') && 'small desktop') ||
            (viewportWidth <= +breakpoints.lg.replace('px', '') && 'large desktop') ||
            (viewportWidth <= +breakpoints.xl.replace('px', '') && 'extra large desktop') ||
            undefined
        );
        };

        this.push({
        event: 'custom.page.view',
        content: {
            id: undefined,
            name: undefined,
            category: undefined,
            environment: process.env.REACT_APP_ENVIRONMENT,
            version: '1.0.0',
            tags: undefined,
            uiExperience: getUiExperience(document.documentElement.clientWidth),
            routing: true
        },
        user: {
            memberId: undefined,
            vistorLoggedInStatus: 'guest',
            vistorIP: undefined,
            emailHash: undefined
        },
        geo: {
            postcode: undefined,
            state: undefined,
            country: undefined
        }
        });

        // Implement GTM
        (function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f: any = d.getElementsByTagName(s)[0],
                j:any = d.createElement(s),
                dl = l !== 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            if (f) f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-MSHMPFX');
        },
}

    // // This event captures a virtual page view
    // pushVirtualPageView({ previousRoute, currentRoute, title }:{ previousRoute: string, currentRoute: string, title: string }) {
    //     this.push({
    //     event: "routeChange",
    //     route: {
    //         previous: previousRoute,
    //         current: currentRoute,
    //         title: title,
    //     },
    //     });
    // },

export default DataLayer;