const treatmentSumScore2Label = {
    "Surgery": ["1"],
    "Surgery and other treatment(s)": ["5", "9", "17", "33", "129", "161", "257", "289", "769", "2049", "2305", "4097", "4129", "4353", "6145"],
    "External Beam Radiation therapy": ["2"],
    "External Beam Radiation therapy and other treatment(s)": ["10", "18", "34", "42", "50", "66", "130", "162", "258", "290", "418", "2050", "2082", "2210", "2338", "4098", "4130"],
    "Surgery and External Beam Radiation therapy": ["3"],
    "Surgery, External Beam Radiation therapy and other treatment(s)": ["35", "131", "163", "259", "291", "419", "2051", "4099", "4131"],
    "Brachytherapy": ["4", "36", "260", "8", "40", "264", "296", "16", "48", "272", "4112"],
    "Hormone Therapy": ["32", "64"],
    "Hormone therapy and other treatment(s)": ["288", "2080", "2336", "4128"],
    "Chemotherapy": ["128"],
    "Chemotherapy and hormone treatment(s)": ["160", "192", "384", "416", "2176", "2208", "2432", "4256"],
    "External Beam Radiation therapy and Brachytherapy": ["6", "38", "262", "294"],
    "Active survelliance/Watchful waiting": ["256", "2304", "4352"],
    "": ["0", "512", "544", "768", "1024", "1056", "1280"]
}

const getTreatmentLabel = (treatmentSumScore: string | undefined) => {
    let treatmentLabel = "";
    if (treatmentSumScore === undefined) {
        return treatmentLabel;
    }
    const treatmentSumScoreKeys = Object.keys(treatmentSumScore2Label)
    for (let key = 0; key < treatmentSumScoreKeys.length; key++) {
        const arrayLength = treatmentSumScoreKeys[key].length;
        for (var j = 0; j < arrayLength; j++) {
            if (treatmentSumScore === treatmentSumScore2Label[treatmentSumScoreKeys[key]][j]) {
                return treatmentLabel=treatmentSumScoreKeys[key];
            }
        }
    }
    return treatmentLabel;
}

export const formatTreatmentLabel = (treatmentSumScore: string | undefined) => {
    const sumId = treatmentSumScore === undefined ? undefined : treatmentSumScore.split(",")[0]
    return getTreatmentLabel(sumId);
};
