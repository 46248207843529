import React from 'react';
import { StyledContainer, StyledTextContainer, StyledImageContainer } from './ErrorPage.styled';
import {
    Heading,
    Text,
    Button
} from '@movember/mo-gel';
import { useHistory } from 'react-router-dom';

export const ErrorPage = ({ image, title, text, altText, ctaText, ctaDestination }) => {
    const history = useHistory();

    return <StyledContainer>
        <StyledImageContainer>
            <img src={image} alt={altText} />
        </StyledImageContainer>
        <StyledTextContainer>
            <Heading align="center">{title}</Heading>
            <Text align="center">
                {text}
            </Text>
            <Button
                primary
                small
                width="auto"
                onClick={() => ctaDestination.charAt(0) === "/" ? history.push(ctaDestination) : window.location.assign(ctaDestination)}
            >{ctaText}</Button>
        </StyledTextContainer>
    </StyledContainer>
}