import styled from "styled-components";
import { Card, Text, Heading } from "@movember/mo-gel";
import { getRGBOpacity } from '@movember/mo-gel/utils/colors';
import { IStyledProps } from "../../models/styledProps";

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeDark500};
  padding: 0 4.4rem 0 4.4rem;
  border-radius: 0;
`;

export const StyledHighLevelInsightWrapper = styled.div`
  padding: 5rem 0 0 0;
  border-bottom: 1px solid ${(props: IStyledProps) => getRGBOpacity(props.theme.colors.brandThemeDark500, "0.5")};;
`;

export const StyledInsightPhrase = styled(Text)`
  padding-right: 22%;
`;

export const StyledInlineScore = styled.span`
  text-decoration: underline;
`;

export const StyledScoreTitle = styled(Text)`
  color: ${(props: IStyledProps) => props.theme.colors.actionPrimary300};
`;

export const StyledInsightHeading = styled(Heading)`
  margin: 0;
  font-size: 2.2.rem;
`;

export const StyledDeepDive = styled.div`
  display: flex;
  padding: 1.5rem 0 4rem 0;
`;

export const StyledDeepDiveHeading = styled(Heading)`
  font-size: 1.6rem;
  width: 50%;
  padding-right: 4rem;
`;

export const StyledButtonWrapper = styled.div`
  width: 50%;
`;

export const StyledGraphWrap = styled.div`
  display: flex;
  padding: 4rem 0 4rem 0;
`;
export const StyledYourScoreWrap = styled.div`
  width: 19%;
`;
export const StyledScore = styled(Heading)`
  padding-top: 1.6rem;
  font-size: 3.1rem;
`;
export const StyledGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const StyledComparisonWrap = styled.div`
  width: 100%;
`;
export const StyledComparisonWrapLow = styled.div`
  width: 100%;
  padding-top: 0;
`;
