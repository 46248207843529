const dateFromDayMonthYear = (date: string): Date | null => {
  const parts = date.split("/");
  if (parseInt(parts[1]) > 12) return null;
  return new Date(parseInt(parts[2].substring(0, 4)), parseInt(parts[1]), parseInt(parts[0]));
};

// convert a birth date string to an age in years
// note. assumes non american date arrangement or other sensible formats
export const birth2Age = (date: string): number | null => {
  const today = new Date();
  let birthDate;
  if (date.includes("/")) {
    birthDate = dateFromDayMonthYear(date);
  } else {
    birthDate = new Date(date);
  }
  if (!birthDate || typeof birthDate.getMonth !== "function") return null;
  return today.getFullYear() - birthDate.getFullYear();
};

// convert a sum of ids postive Interger to an array of unique ids that make up that sum
// ids are "1" + "2 the powers" eg.(1,2,4,8,16,32) all other numbers are combinations of smaller ids
const sumPartsOf2Power = (id: number): number[] => (id ? [...sumPartsOf2Power(id & ~-id), id & -id] : []);

// convert an numerical id to a treatment name
const id2Treatment = (id: number | string): string | null => {
  const treatments = {
    "1": "Surgery",
    "2": "Radiotherapy",
    "4": "Brachytherapy - HDR",
    "8": "Brachytherapy - LDR",
    "16": "Brachytherapy - Unknown",
    "32": "Adjuvant ADT (Chemical)",
    "64": "ADT - Surgical",
    "128": "Chemotherapy",
    "256": "WWAS",
    "512": "Whole-gland Therapy (HIFU)",
    "1024": "Focal-gland Therapy",
    "2048": "Other",
    "4096": "Unknown",
  };
  return treatments[`${id}`] ?? null;
};

// convert a sum of ids to a string of treatment names
// this data can come in the format "sumId,name"
export const formatFirstTreatmentSumScore = (FirstTreatmentSumScore: string, delimiter: string = ", "): string => {
  const sumId = parseInt(FirstTreatmentSumScore.split(",")[0]);
  if (sumId > 4096) return "";
  const ids = sumPartsOf2Power(sumId);
  const names = ids.map((id) => id2Treatment(id));
  return names.join(delimiter);
};

// convert userdata FirstTreatmentSumScore to rods treatment ids
export const treatmentSumScore2Id = (FirstTreatmentSumScore: string): string | false => {
  const map = {
    "1": ["1", "5", "9", "17", "33", "129", "161", "257", "289", "769", "2049", "2305", "4097", "4129", "4353", "6145"],
    "3": ["2", "10", "18", "34", "42", "50", "66", "130", "162", "258", "290", "418", "2050", "2082", "2210", "2338", "4098", "4130"],
    "4": ["3", "35", "131", "163", "259", "291", "419", "2051", "4099", "4131"],
    "5": ["4", "36", "260"],
    "6": ["8", "40", "264", "296", "16", "48", "272", "4112"],
    "8": ["32", "64", "288", "2080", "2336", "4128"],
    "9": ["128", "160", "192", "384", "416", "2176", "2208", "2432", "4256"],
    "10": ["6", "38", "262", "294"],
    "11": ["256", "2304", "4352"]
  };
  const sum = FirstTreatmentSumScore.split(",")[0];
  const keys = Object.keys(map);
  for (let i = 0; i < keys.length; i++) {
    if (map[keys[i]].includes(sum)) {
      return keys[i];
    }
  }
  return false;
};

// convert a NCCN Id to a nice readable name for this grade
export const nccn2Grade = (NCCN: number | string): string | null => {
  const grades = {
    "-1": null, // Not Classified
    "1": "Low Risk",
    "2": "Intermediate Risk",
    "3": "High Risk",
    "4": "Very High Risk",
    "5": "Metastatic",
  };
  return grades[`${NCCN}`] ?? null;
};

/// get index from treatment age and nccn
export const getIndex = (userData: any): string | false => {
  if (!userData) return false;
  const treatment = userData.FirstTreatmentSumScore ? treatmentSumScore2Id(userData.FirstTreatmentSumScore) : false;
  const age = userData.Column1 ? new Date().getFullYear() - parseInt(userData.Column1) : false;
  const nccn = userData.NCCN && userData.NCCN > 0 ? (userData.NCCN === "4" ? "3" : userData.NCCN) : "0";
  if (!treatment || !age) return false;
  return ("00" + treatment).slice(-2) + ("000" + age).slice(-3) + nccn;
};
