import { MoThemeProvider } from "@movember/mo-gel";
import { TrueNorthLight } from "../../theme/TrueNorthLightTheme";
import { ErrorContainer, ErrorContent, StyledSubHeadingContainer, StyledSubHeading, StyledMessageContainer } from "./InsufficientDataError.styled";
import { Heading, Button } from "@movember/mo-gel"

export const InsufficientDataError = () => {

    const handleClick = () => {
        process.env.REACT_APP_ENV === "prod" ? window.location.assign(`https://truenorth.movember.com/`)
        : window.location.assign(`https://truenorth.test.movember.com/`)
    }

    return (
        <ErrorContainer>
            <MoThemeProvider theme={TrueNorthLight}>
                <ErrorContent>
                    <StyledSubHeadingContainer>
                        <StyledSubHeading>HIGH LEVEL COMPARISONS</StyledSubHeading>
                    </StyledSubHeadingContainer>
                    <StyledMessageContainer>
                        <Heading level="h3">
                            Unfortunately we don't have sufficient data 
                            <br />
                            to present meaningful comparisons.
                        </Heading>
                        <Heading level="h5" fontWeight="100">
                            However, we have other resources and information to help tackle
                            <br />
                            common issues experienced after prostate cancer treatment.
                        </Heading>
                    </StyledMessageContainer>
                    <Button primary width="auto" onClick={handleClick}>
                        Explore Resources
                    </Button>
                </ErrorContent>
            </MoThemeProvider>
        </ErrorContainer>
    )
    
}