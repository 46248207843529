import styled from 'styled-components';
import { Text, Modal } from '@movember/mo-gel';
import { IStyledProps } from '../../models/styledProps';



export const StyledModal = styled(Modal)`
    width: 100%;
    height: 100%;

    @media print {
        display: none;
    }
`;
export const StyledContentContainer = styled.div`
    width: 481px;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props: IStyledProps) => props.theme.colors.white};
    border-radius: 4px;
    border: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeLight500};

    & > button:nth-of-type(1) {
        margin-bottom: 1rem;
    }
`;

export const StyledText = styled(Text)`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 351px;
    margin-bottom: 1rem;
    white-space: pre-line;
    color: ${(props: IStyledProps) => props.theme.colors.brandThemeDark900};

    &:nth-last-of-type(1) {
        margin-bottom: 2rem;
    }

    & > span, & > a {
        border-bottom: 1px solid ${(props: IStyledProps) => props.theme.colors.black};
    }

    & > a {
        padding-bottom: 0;

        &:hover {
            border-bottom: 1px solid ${(props: IStyledProps) => props.theme.colors.actionPrimary300};
        }
    }
`;