import styled from 'styled-components';
import { Deck } from '@movember/mo-gel';
import { IStyledProps } from '../../models/styledProps';

interface IStyledBlurWrapper extends IStyledProps {
  blur?: boolean;
}

const blurStyling = `
  filter: blur(4px);
  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='4')
`;

export const StyledHighLevelContentContainer = styled.div`
  max-width: ${(props: IStyledProps) => props.theme.breakpoints.lg};
  width: 100%;
  margin: 0 auto;
  padding: 0 2.4rem;
`;

export const StyledBlurWrapper = styled.div<IStyledBlurWrapper>`
  ${(props) => props.blur && blurStyling};
`;

export const StyledOutcomesDeck = styled(Deck)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
