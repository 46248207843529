import React from "react";
import {
  StyledPageFooterBackgroundContainer,
  StyledFooterContainer,
  StyledFooterLogo,
  StyledFooterWelcome,
  StyledFlag,
} from "./Footer.styled";
import { Text } from "@movember/mo-gel";
import logo from "../../assets/true_north_logo_black.svg";
import flagAboriginal from "../../assets/flag_aboriginal.svg";
import flagTorresStraight from "../../assets/flag_torres_straight.svg";

export const Footer: React.FC<any> = () => {
  return (
    <StyledPageFooterBackgroundContainer>
      <StyledFooterContainer>
        <StyledFooterLogo>
          <img src={logo} alt="True North" height="50" />
        </StyledFooterLogo>
        <StyledFooterWelcome>
          <StyledFlag src={flagAboriginal} alt="True North" height="40" />
          <StyledFlag src={flagTorresStraight} alt="True North" height="40" />
          <Text tag="p" level="normal" marginBottom="0" color="rgba(0, 0, 0, 0.5)" marginTop="0.9rem">
            True North acknowledges the Traditional Custodians of the land and waters of Australia, and pay respect to
            all Elders - past, present and emerging.
          </Text>
        </StyledFooterWelcome>
      </StyledFooterContainer>
    </StyledPageFooterBackgroundContainer>
  );
};
