import styled from 'styled-components';
import { IStyledProps } from '../../models/styledProps';

export const StyledTextContainer = styled.div`
  text-align: center;
  padding: 1rem;
`;

export const StyledLogoGroupContainer = styled.div`
  display: flex;
  height: 100%;
  border-top: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeLight500};
  border-bottom: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeLight500};
  margin-bottom: 3.1rem
`;

export const StyledLogoContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  height: 8.5rem;
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  justify-content: center;
  background-color: transparent;

  &:not(:last-child){
    border-right: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeLight500};
  }
`;

export const StyledImage = styled.img`
  width: 50%;
  height: auto;
  object-fit: contain;
`;