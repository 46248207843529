import React, { useContext } from 'react';
import { Button } from '@movember/mo-gel';
import { StyledContentContainer, StyledText, StyledModal } from './SaveModal.styled';
import { AppContext } from '../../AppContext';

export const SaveModal = () => {
    const { showSaveModal, setShowSaveModal } = useContext(AppContext);

    const handleClick = () => {
        setShowSaveModal(false);
        window.print();
    }

    return <StyledModal isOpen={showSaveModal} showCloseBtn={false} data-testid="save-modal">
        <StyledContentContainer>
            <StyledText tag="p" data-testid="support-text">
                To save this page to your computer, please select "Print as PDF" as the print destination.
            </StyledText>
            <Button primary onClick={() => handleClick()}>Save</Button>
            <Button tertiary onClick={() => setShowSaveModal(false)}>Cancel</Button>
        </StyledContentContainer>
    </StyledModal>
}