import {STORAGE_TYPES} from "../constants";
import { getCookieValue } from "../utils/cookie"

declare global {
    interface Window {
        hj: any;
    }
}

const Hotjar = {
    initialize() {
        if(!process.env.REACT_APP_HOTJAR_ID) return; // only init with valid id
        if(!getCookieValue(STORAGE_TYPES.cookiesAccepted)) return; //Cookies must be accepted
        if(window.hj) return;

        const HOTJAR_SV = 6;
        const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
        if(!HOTJAR_ID) return;

        (function(h: any, o: any, t: any, j: any, a: any, r: any){
            let x = a;
            let y = r;
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:HOTJAR_ID,hjsv:HOTJAR_SV};
            x=o.getElementsByTagName('head')[0];
            y=o.createElement('script');y.async=1;
            y.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            x.appendChild(y);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=',null,null)
    },

};

export default Hotjar;