import styled from 'styled-components';
import { Nav } from '@movember/mo-gel';


export const HeaderContainer = styled.div`
    position: fixed;

    @media print {
        display: none;
    }
`

export const StyledHeader = styled(Nav)`
    position:relative;
`