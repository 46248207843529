import styled from 'styled-components';
import { IStyledProps } from '../../models/styledProps';

export const StyledPageFooterBackgroundContainer = styled.div`
  background: ${(props: IStyledProps) => props.theme.colors.brandThemeLight100};
  width: 100%;
`;

export const StyledFooterContainer = styled.div`
  max-width: ${(props) => props.theme.breakpoints.lg};
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 1.8rem 2.4rem 1.8rem 2.4rem;
`;

export const StyledFooterLogo = styled.div`
  flex: 0 1 auto;
  padding: 2.2rem 2.2rem 2.2rem 0;
  border-right: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeLight500};
`;

export const StyledFooterWelcome = styled.div`
  flex: 0 1 auto;
  padding: 2.2rem;
`;

export const StyledFlag = styled.img`
  margin-right: 0.5rem;
`;
