import styled, { css } from 'styled-components';
import { Text } from '@movember/mo-gel';
import { IStyledProps } from '../../models/styledProps';
import { getRGBOpacity } from '@movember/mo-gel/utils/colors';

interface IManScore {
    manScore: number;
}

interface IOtherMenScore {
    otherMenScoreStart: number;
    otherMenScoreEnd: number;
}

interface ILabel extends IStyledProps {
    isManScore: boolean;
}

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 55px;
`;

export const StyledGraphTitle = styled(Text)`
    color: ${(props: IStyledProps) => props.theme.colors.actionPrimary300};
    margin-bottom: 3rem;
`;

export const StyledGraphContainer = styled.div`
    position: relative;

    /* Minimum graph value */
    &:before {
        content: '0';
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(100%);
        color: ${(props: IStyledProps) => getRGBOpacity(props.theme.colors.white, "0.6")};
    }

    /* Maximum graph value */
    &:after {
        content: '100';
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translateY(100%);
        color: ${(props: IStyledProps) => getRGBOpacity(props.theme.colors.white, "0.6")};
    }
`;

export const StyledGraph = styled.div`
    position: relative;
    width: 100%;
    height: 21px;
    border: 2px solid ${(props: IStyledProps) => props.theme.colors.white};
    border-bottom: none;

    /* Center verticle line in the graph */
    &:after {
        content: '';
        position: absolute;
        height: 18px;
        width: 2px;
        background-color: ${(props: IStyledProps) => props.theme.colors.white};
        left: 50%;
        transform: translateX(-50%);
    }
`;

export const StyledManPlot = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${(props: IStyledProps) => props.theme.colors.actionPrimary300};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: ${(props: IManScore) => `${props.manScore}%`};
    transform: translate(-50%, -50%);
    z-index: 2;

    & > svg {
        width: 19.52px;
        height: 23px;
        z-index: 1;
    }
`;

export const StyledOtherMenPlot = styled.div<IOtherMenScore>`
    position: absolute;
    width: ${(props: IOtherMenScore) => `${props.otherMenScoreEnd - props.otherMenScoreStart}%`};
    height: 40px;
    left: ${(props: IOtherMenScore) => `${props.otherMenScoreStart}%`};
    background-color: ${(props: IStyledProps) => props.theme.colors.brandThemeLight500};
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1;
`;

export const StyledLabelContainer = styled.div<ILabel>`
    width: 2px;
    height: ${(props: ILabel) => props.isManScore ? '30px' : '25px'};
    background-color: ${(props: ILabel) => props.isManScore ? props.theme.colors.actionPrimary300 : props.theme.colors.brandThemeLight500};
    transform: ${(props: ILabel) => props.isManScore ? 'translateY(-100%)' : 'translateY(100%)'};
    display: flex;
    justify-content: center;
    position: absolute;

    & > span {
        position: absolute;
        width: max-content;
    }

    ${(props: ILabel) => props.isManScore ? css`
        transform: translateY(-50%);

        & > span {
            top: 0;
            transform: translateY(-100%);
            
        }
    ` : css`
        & > span {
            bottom: 0;
            transform: translateY(100%);
        }
    `};
`;