import styled, { css } from 'styled-components';
import { Heading, Text, Button } from '@movember/mo-gel';
import { IStyledProps } from '../../models/styledProps';

interface ITabStyles extends IStyledProps {
    isSelected: boolean;
}

export const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props: IStyledProps) => props.theme.colors.white};
`;

export const StyledTabs = styled.div`
    width: 100%;
    padding: 8px;
    display: flex;
    border-top: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeLight500};
    border-bottom: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeLight500};
`;

export const StyledTab = styled.div`
    height: 126px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    ${(props: ITabStyles) => props.isSelected && css`color: ${props.theme.colors.actionPrimary300}`};

    &:hover {
        color: ${(props: IStyledProps) => props.theme.colors.actionPrimary300};
    }

    &:not(:nth-last-of-type(1)) {
        border-right: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeLight500};
    }

    & > span {
        font-size: 18px;
    }
`;

export const StyledTabContent = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    width: 100%;
`;

export const StyledImage = styled.img`
    min-width: 50%;
    min-height: 696px;
    max-height: 912px;
    object-fit: contain;
`;

export const StyledTextContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const StyledHeading = styled(Heading)`
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    white-space: pre;
    margin-bottom: 1.8rem;
`;

export const StyledText = styled(Text)`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 321px;
    margin-bottom: 1rem;
    white-space: pre-line;

    @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
        width: 404px;
    }

    &:nth-last-of-type(1) {
        margin-bottom: 40px;
    }

    & > a {
        border-bottom: 1px solid ${(props: IStyledProps) => props.theme.colors.black};
        padding-bottom: 0;

        &:hover {
            border-bottom: 1px solid ${(props: IStyledProps) => props.theme.colors.actionPrimary300};
        }
    }
`;

export const StyledButton = styled(Button)`
    width: 180px;
    height: 49px;
    font-size: 16px;
    line-height: 28px;
`;