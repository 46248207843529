import styled from 'styled-components';
import { Heading, Link } from '@movember/mo-gel';

export const StyledContainer = styled.div`
  display: flex;
  padding: 5.3rem 4rem;
  width: 100%;
  max-width: ${(props) => props.theme.breakpoints.lg};
  border-top: 1px solid ${(props) => props.theme.colors.actionPrimary300};
  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    padding: 5.3rem 5rem;
  }
`;

export const StyledHeadingContainer = styled.div`
  width: 30%;
  margin-right: 5rem;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    margin-right: 8rem;
  }
`;

export const StyledHeading = styled(Heading)`
  width: 280px;
`;

export const StyledTextContainer = styled.div`
  width: 453px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 480px;
  }
`;

export const StyledLink = styled(Link)`
  border-bottom-color: ${(props) => props.theme.colors.white};
`;

export const StyledPhone = styled.strong`
  white-space: nowrap;
`;

