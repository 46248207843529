import { ROOT_DOMAIN } from "../constants";

export const getCookieValue = (key: string): string | null => {
  const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  const value = b ? b.pop() : null;
  return value ?? null;
};

// set cookie in browser
// nb. This sets the cookie to the base domain (unless localhost)
// only use for cookies to be accessed from the same registered domain.
// defaults to expire 2 years from now, on the root path
export const storeCookie = (key: string, value: string, days: number = 730, path: string = "/") => {
  const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
  const domain = window.location.hostname === "localhost" ? "" : `; domain=.${ROOT_DOMAIN}`;
  document.cookie = `${key}=${value}; expires=${expires}; path=${path}; SameSite=strict${domain}`;
};
