import React, { useContext } from "react";
import {
  StyledAttributeContainer,
  StyledTreatmentContainer,
  StyledAttributeGroupContainer,
  StyledContentContainer,
  StyledSubHeading,
  StyledSubHeadingContainer,
  StyledHeading
} from "./InsightHeader.styled";
import { Heading, Text } from "@movember/mo-gel";
import { AppContext } from "../../AppContext";
import { formatTreatmentLabel } from "../../utils/treatmentLabelMapper";
import {birth2Age} from "../../utils/dataParsing"

export const InsightHeader: React.FC<any> = ({insightHeading, heading, description}) => {
  const { userData } = useContext(AppContext);
  const treatment = formatTreatmentLabel(userData?.FirstTreatmentSumScore);
  const age = userData?.Column1 ? birth2Age(userData.Column1) : "";

  return (
    <StyledContentContainer>
      <StyledSubHeadingContainer>
        <StyledSubHeading>{insightHeading}</StyledSubHeading>
      </StyledSubHeadingContainer>
      <Heading level="h1">{heading}</Heading>
      <StyledHeading level="h5" fontWeight="100">
        {description}
      </StyledHeading>
      <StyledAttributeGroupContainer>
        {treatment && (
          <StyledTreatmentContainer>
            <div>
              <Text fontWeight="bold">Your treatment</Text>
            </div>
            <Text>{treatment}</Text>
          </StyledTreatmentContainer>
        )}
        {age && (
          <StyledAttributeContainer>
            <div>
              <Text fontWeight="bold">Your age</Text>
            </div>
            <Text>{age} yrs</Text>
          </StyledAttributeContainer>
        )}
      </StyledAttributeGroupContainer>
    </StyledContentContainer>
  );
};
