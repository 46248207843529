import React from 'react';
import { IDetailScore, ISummaryScore } from './models/outcome';
import { IUser } from './models/user';

export interface IAppContext {
  highLevels: ISummaryScore[] | null | undefined;
  lowLevels: IDetailScore[] | null | undefined;
  showLowLevelInsight: boolean; 
  showDistressModal: boolean;
  showSaveModal: boolean;
  setShowLowLevelInsight: (val: boolean) => void;
  categoryDomain: string | null;
  handleViewLowLevel: (val: string) => void;
  userData: IUser | null | undefined;
  setShowDistressModal: (val: boolean) => void;
  setShowSaveModal: (val: boolean) => void;
}

export const AppContext = React.createContext<IAppContext>({} as IAppContext);

export const AppContextConsumer = AppContext.Consumer;

export const AppContextProvider = AppContext.Provider;
