import NotFound from './assets/404.svg';
import ServerError from './assets/500.svg';

export const STORAGE_TYPES = {
    cookiesAccepted: "cookiesAcceptedAt",
    isBroSupPORT: "tnt_mgoBJApNNsNt2i"
  };

export const ROOT_DOMAIN = 'movember.com';

export const TOKEN_API = process.env.REACT_APP_API ? `${process.env.REACT_APP_API}token` : '';

export const DATA_PATH = process.env.REACT_APP_DATA_PATH ? `${process.env.REACT_APP_DATA_PATH}` : '';

export const TNOA_URL = process.env.REACT_APP_ENV === "prod" ? "https://truenorth.movember.com" : "https://truenorth.test.movember.com";

export const ErrorPages = {
  404: {
    title: "Sorry, we're a bit lost.",
    text: "Unfortunately we can't find what you're looking for, your best bet is to head back to the home page.",
    image: NotFound,
    altText: "Man holding a map upside down seems to be lost",
    ctaText: "Home",
    ctaDestination: "/"
  },
  500: {
    title: "Oops!",
    text: "Something has gone a bit wrong under the hood. We've got information and resources to help tackle common issues experienced after prostate cancer treatment.",
    image: ServerError,
    altText: "Car with the hood open and smoke coming out",
    ctaText: "Explore resources",
    ctaDestination: TNOA_URL
  },
};