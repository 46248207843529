export const categoryNameToFilterValue = {
    "Urinary Issues": { filterType: "lifechange", filterValue: "ur"},
    "Bowel Function": { filterType: "lifechange", filterValue: "bowelissues"},
    "Sexual Function": { filterType: "lifechange", filterValue: "si"},
    "Hormonal Issues": { filterType: "treatment", filterValue: "ht"},
    "Improving Sexual Function": { filterType: "lifechange", filterValue: "si"},
}

export const keyToCategoryNameMapper: { [key: string]: string } = {
    urinary_issues: "Urinary Issues",
    bowel_function: "Bowel Function",
    sexual_function: "Sexual Function",
    hormonal_issues: "Hormonal Issues",
    improving_sexual_function: "Improving Sexual Function",
  };
