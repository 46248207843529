import React, { useContext } from "react";
import { MoThemeProvider } from "@movember/mo-gel";
import { AppContext } from "../../AppContext";
import { StyledOutcomesDeck, StyledHighLevelContentContainer, StyledBlurWrapper } from "./HighLevelInsights.styled";
import HighLevelComparison from "../../components/HighLevelComparison/HighLevelComparison";
import { InsightHeader } from "../../components/InsightHeader/InsightHeader";
import { DistressModal } from "../../components/DistressModal/DistressModal";
import { SaveInsights } from "../../components/SaveInsights/SaveInsights";
import { InsufficientDataError } from "../../components/InsufficientDataError/InsufficientDataError";
import { TrueNorthLight } from "../../theme/TrueNorthLightTheme";
import TipsAndCautions from "../../components/TipsAndCautions/TipsAndCautions";
import {birth2Age} from "../../utils/dataParsing";
import {formatTreatmentLabel} from "../../utils/treatmentLabelMapper";
import { highLevelComparisons} from "../../data/highLevelComparisons"


const HighLevelInsights = () => {
  const { userData } = useContext(AppContext);
  const treatment = formatTreatmentLabel(userData?.FirstTreatmentSumScore);
  const age = userData?.Column1 ? birth2Age(userData.Column1) : "";

  const { showDistressModal, highLevels } = useContext(AppContext);
  
  if (treatment==="" || age==="" || !highLevels) {
    return <InsufficientDataError />
  } else {
    return (
      <StyledHighLevelContentContainer>
        <MoThemeProvider theme={TrueNorthLight}>
          <DistressModal />
        </MoThemeProvider>
        <StyledBlurWrapper blur={showDistressModal}>
          <InsightHeader
            insightHeading={"Your Comparison Overview"}
            heading={"Men like you"}
            description={"We’ve pulled together comparisons of your experience in different areas of bother, based on your survey responses and what other men similar to you are likely to report."}
          />
          <SaveInsights />
          {highLevelComparisons && (
            <StyledOutcomesDeck>
              {highLevelComparisons?.map((comparison, index: number) => {
                return <HighLevelComparison Comparison={comparison} key={`high-level-comparison-${index}`} />;
              })}
            </StyledOutcomesDeck>
          )}
          <TipsAndCautions />
        </StyledBlurWrapper>
      </StyledHighLevelContentContainer>
    );
  }
};

export default HighLevelInsights;
