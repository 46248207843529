import React, { useContext } from 'react';
import { Button, Link } from '@movember/mo-gel';
import { StyledContentContainer, StyledHeading, StyledText, StyledModal } from './DistressModal.styled';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';

export const DistressModal = () => {
    const history = useHistory();
    const { showDistressModal, setShowDistressModal } = useContext(AppContext);

    const inlineLink = (text: string, href: string) => <Link primary href={href} target="__blank">{text}</Link>;

    return <StyledModal isOpen={showDistressModal} showCloseBtn={false} data-testid="distress-modal">
        <StyledContentContainer>
            <StyledHeading>
                {`Before we show you\nthe comparisons`}
            </StyledHeading>
            <StyledText tag="p" data-testid="support-text">
                If you feel anxious or worried about what you’re seeing, remember you can turn to trusted family and friends to support you. You can also reach out to your GP, cancer specialist, the <strong>13 11 20</strong> nurses at {inlineLink("Cancer Council Victoria", "https://www.cancervic.org.au/")} or the mental health team at {inlineLink("Beyond Blue", "https://www.beyondblue.org.au/")} on <strong>1300 22 44 36</strong>.
            </StyledText>
            <Button primary onClick={() => setShowDistressModal(false)}>Continue</Button>
            <Button tertiary onClick={() => {
                setShowDistressModal(false);
                history.push('/');
            }}>Back</Button>
        </StyledContentContainer>
    </StyledModal>
}