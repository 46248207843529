import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TrueNorthLight } from '../theme/TrueNorthLightTheme';

// Add any routes that needs to use the Light theme to this array. '/' denoted the home page
const LIGHT_THEME_ROUTES = ['/', '/notfound', '/error'];

// This hook returns the appropriate theme to set in the MoThemeProvider based on the route
export const useMoTheme = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [theme, setTheme] = useState<any>(null);

  // Funtion to set the Light or Dark theme based on route path
  function setAppTheme(path) {
    if (LIGHT_THEME_ROUTES.includes(path)) {
      setTheme(TrueNorthLight);
    } else {
      setTheme({ ...TrueNorthLight, dark: true });
    }
  }

  useEffect(() => {
    setAppTheme(pathname);

    const unlisten = history.listen((location) => {
      setAppTheme(location.pathname);
    });

    return () => unlisten();
  }, [history, pathname]);

  return theme;
}
