import React, { useContext } from "react";
import { ReactComponent as PersonIcon } from "../../assets/person.svg";
import {
  StyledLowLevelGraph,
  GraphContainer,
  GraphTitle,
  GraphSummaryText,
  Bar,
  Filler,
  GraphLabelContainer,
  LeftGraphLabel,
  RightGraphLabel,
  LabelScore,
  LabelTextContainer,
  LeftLegendContainer,
  RightLegendContainer,
  LegendSquare,
  StyledManPlot,
  IconText,
} from "./LowLevelGraph.styled";
import { ThemeContext } from "styled-components";
import { Text } from "@movember/mo-gel";

export interface ILowLevelBarStack {
  title?: string;
  phrase?: string;
  mansScore?: number | false; // indicates which bar the man's score sits in i.e. 1st = 1, 2nd = 2 or 3rd = 3 segment
  segment1: ILowLevelBarSegment;
  segment2: ILowLevelBarSegment;
  segment3?: ILowLevelBarSegment;
}

interface ILowLevelBarSegment {
  score?: number; // Scores should be percentages
  labels?: string[];
}

export const LowLevelGraph: React.FC<ILowLevelBarStack> = (barStack: ILowLevelBarStack) => {
  const { title, phrase, mansScore, segment1, segment2, segment3 } = barStack;
  const theme = useContext(ThemeContext);

  const renderPersonIcon = () => {
    return (
      <StyledManPlot>
        <PersonIcon data-testid="person-icon" />
        <IconText>
          <Text level="normal" color="white" data-testid="man-label">
            You
          </Text>
        </IconText>
      </StyledManPlot>
    );
  };

  const renderLabels = (label: string, legendColor?: string) => {
    return (
      <>
        <LabelTextContainer>
          <Text>{label}</Text>
        </LabelTextContainer>
        {legendColor && <LegendSquare legendColor={legendColor} />}
      </>
    );
  };

  const renderRightLabels = () => {
    return (
      <RightGraphLabel>
        <LabelScore level="h4">{`${
          segment3?.score !== undefined && segment2.score !== undefined
            ? segment2.score + segment3.score
            : segment2.score
        }%`}</LabelScore>
        {segment2.labels?.map((label, index) => {
          return (
            <RightLegendContainer key={index}>
              {renderLabels(label, segment3?.labels && theme.colors.white)}
            </RightLegendContainer>
          );
        })}
        {segment3?.labels?.map((label, index) => {
          return <RightLegendContainer key={index}>{renderLabels(label, "striped")}</RightLegendContainer>;
        })}
      </RightGraphLabel>
    );
  };

  const renderLeftLabels = () => {
    return (
      <LeftGraphLabel>
        <LabelScore level="h4">{`${segment1.score}%`}</LabelScore>
        {segment1.labels?.map((label, index) => {
          return <LeftLegendContainer key={index}>{renderLabels(label)}</LeftLegendContainer>;
        })}
      </LeftGraphLabel>
    );
  };

  const renderBarGraph = () => {
    return (
      <Bar>
        <Filler fillerWidth={segment1.score} fillerColor={theme.colors.brandThemeLight500}>
          {mansScore === 1 && renderPersonIcon()}
        </Filler>
        <Filler fillerWidth={segment2.score} fillerColor={theme.colors.white}>
          {mansScore === 2 && renderPersonIcon()}
        </Filler>
        <Filler fillerWidth={segment3?.score} fillerColor={"striped"}>
          {mansScore === 3 && renderPersonIcon()}
        </Filler>
      </Bar>
    );
  };

  return (
    <StyledLowLevelGraph>
      {title && <GraphTitle level={"h4"}>{title}</GraphTitle>}
      {phrase && <GraphSummaryText>{phrase}</GraphSummaryText>}
      <GraphContainer>{renderBarGraph()}</GraphContainer>
      <GraphLabelContainer>
        {renderLeftLabels()}
        {renderRightLabels()}
      </GraphLabelContainer>
    </StyledLowLevelGraph>
  );
};
