import React from 'react';
import {
  StyledLogoContainer,
  StyledImage,
  StyledLogoGroupContainer,
  StyledTextContainer
} from './Stakeholder.styled';
import trueNorthLogo from '../../assets/true_north_logo_black.svg';
import monashLogo from '../../assets/monash_logo.svg';
import vahiLogo from '../../assets/vahi_logo.svg';
import theAlfredLogo from '../../assets/the_alfred_logo.png';
import { Text } from '@movember/mo-gel';

export const Stakeholder: React.FC<any> = () => {
  return (
    <>
      <StyledTextContainer>
        <Text fontWeight={"bold"}>BroSupPORT is a product of a partnership between</Text>
      </StyledTextContainer>
      <StyledLogoGroupContainer>
        <StyledLogoContainer>
          <StyledImage src={monashLogo} alt="Monash Logo"></StyledImage>
        </StyledLogoContainer>
        <StyledLogoContainer>
          <StyledImage src={vahiLogo} alt="Victorian Agency for Health and Information Logo"></StyledImage>
        </StyledLogoContainer>
        <StyledLogoContainer>
          <StyledImage src={trueNorthLogo} alt="True North Logo"></StyledImage>
        </StyledLogoContainer>
        <StyledLogoContainer>
          <StyledImage src={theAlfredLogo} alt="The Alfred Logo"></StyledImage>
        </StyledLogoContainer>
      </StyledLogoGroupContainer>
    </>
  );

};