import React from 'react';
import { TabSelector } from '../TabSelector/TabSelector';
import {
  StyledLandingPageContentContainer,
} from './LandingPage.styled';
import { PageHeader } from './PageHeader';
import { SignpostOA } from './SignpostOA';
import { Stakeholder } from './Stakeholder';
import { Footer } from './Footer'

export const LandingPage: React.FC<any> = () => {
  return (
    <>
        <PageHeader />
      <StyledLandingPageContentContainer>
        <TabSelector />
        <SignpostOA />
        <Stakeholder />
      </StyledLandingPageContentContainer>
        <Footer />
    </>
  );

};