import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import { Spinner, MoThemeProvider, Text } from "@movember/mo-gel";
import { Helmet } from "react-helmet";
import { Header } from "./components/Header/Header";
import { StyledApp, StyledSpinnerContainer, GlobalStyle } from "./App.styled";
import { TrueNorthLight } from "./theme/TrueNorthLightTheme";
import { AppContextProvider, IAppContext } from "./AppContext";
import HighLevelInsights from "./containers/HighLevelInsights/HighLevelInsights";
import { keyToCategoryNameMapper } from "./utils/categoryDomainMapper";
import { Cookies } from "./components/Cookies/Cookies";
import { SaveModal } from "./components/SaveModal/SaveModal"
import { ISummaryScore, IDetailScore } from "./models/outcome";
import { IUser } from "./models/user";
import { LandingPage } from "./components/LandingPage/LandingPage";
import { useMoTheme } from "./hooks/useMoTheme";
import { getCookieValue, storeCookie } from "./utils/cookie";
import { ErrorPages, STORAGE_TYPES, TOKEN_API, DATA_PATH } from "./constants";
import { ErrorPage } from "./containers/ErrorPage/ErrorPage";
import { getIndex } from "./utils/dataParsing";

const LowLevelInsights = React.lazy(() => import("./containers/LowLevelInsights/LowLevelInsights"));

function App() {
  const getToken = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get("token");
  };

  const [showLowLevelInsight, setShowLowLevelInsight] = useState(false);
  const [highLevels, setHighLevels] = useState<null | ISummaryScore[]>();
  const [lowLevels, setLowLevels] = useState<null | IDetailScore[]>();
  const [categoryDomain, setCategoryDomain] = useState<null | string>(null);
  const [token] = useState<null | string>(getToken());
  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);
  const [isValidatingToken, setIsValidatingToken] = useState<boolean | null>(false);
  const [userData, setUserData] = useState<null | IUser>();
  const [showDistressModal, setShowDistressModal] = useState<boolean>(true);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const theme = useMoTheme();
  let history = useHistory();
  const categoryMapperKeys = Object.keys(keyToCategoryNameMapper);

  const handleViewLowLevel = (categoryLabel: string) => {
    setCategoryDomain(categoryLabel);
    setShowLowLevelInsight(true);
    window.scrollTo(0, 0);
  };

  const appContext: IAppContext = {
    showLowLevelInsight,
    setShowLowLevelInsight,
    showDistressModal,
    showSaveModal,
    lowLevels,
    highLevels,
    categoryDomain,
    handleViewLowLevel,
    userData,
    setShowDistressModal,
    setShowSaveModal
  };

  const updateTokenStatus = (isValid: boolean | null) => {
    setIsTokenValid(isValid);
    setIsValidatingToken(false);
  };

  const verifyToken = useCallback(async () => {
    setIsValidatingToken(true);

    if (token === null) {
      updateTokenStatus(null);
      history.push("/error");
    } else {
      try {
        const response = await fetch(TOKEN_API, {
          method: "POST",
          headers: {
            "user-data-token": token,
          },
        });
        if (response.status === 200) {
          const user = await response.json();
          // const userData = mapUserData(user);
          const userData = user;
          setUserData(userData);
          updateTokenStatus(true);

          // get index to lookup
          const index = getIndex(userData);
          if (index) {
            fetch(`${DATA_PATH}${index}.json`)
              .then((response) => {
                if (!response.ok) {
                  throw Error(response.statusText);
                }
                return response;
              })
              .then((response) => response.json())
              .then((data) => {
                setHighLevels(data.highLevel);
                setLowLevels(data.lowLevel);
              })
              .catch((error) => {
                console.log("fetch data error");
              });
          }

          if (!getCookieValue(STORAGE_TYPES.isBroSupPORT)) {
            const newIsBrosupportCookie = new Date().toString() + "|BroSupPORT";
            storeCookie(STORAGE_TYPES.isBroSupPORT, newIsBrosupportCookie);
          }
        } else {
          updateTokenStatus(false);
          history.push("/error");
        }
      } catch (error) {
        updateTokenStatus(false);
        history.push("/error");
      }
    }
  }, [token, history]);

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  useEffect(() => {
    const unsubscribeHistory = history.listen(() => {
      // In case User arrives on any page with an invalid token
      // Redirect back to the error page.
      if (!isTokenValid && history.location.pathname !== "/error") history.push("/error");
      // In case User has the Save Modal open and they use back or forward to navigate the page
      setShowSaveModal(false);
    });

    return () => unsubscribeHistory();
  }, [isTokenValid, history]);


  return (
    <MoThemeProvider theme={theme}>
      <GlobalStyle />
      <Helmet>
        <link
          href={`https://fonts.googleapis.com/css?family=${TrueNorthLight.defaultFontFamily}:300,300i,500,400i,700,700i,900&display=swap`}
          rel="stylesheet"
        />
      </Helmet>
      <AppContextProvider value={appContext}>
        <StyledApp>
          {isValidatingToken ? (
            <StyledSpinnerContainer>
              <Spinner />
              <Text tag="p" level="small" marginTop="1rem" fontWeight="bold">
                Validating Token
              </Text>
            </StyledSpinnerContainer>
          ) : (
            <>
              <Cookies />
              <Header />
              <SaveModal />
              <Switch>
                <Route exact path={`/`}>
                  <LandingPage />
                </Route>
                <Route exact path={`/compare`}>
                  <HighLevelInsights />
                </Route>
                <Route exact path="/notfound">
                  <ErrorPage
                    image={ErrorPages[404].image}
                    title={ErrorPages[404].title}
                    text={ErrorPages[404].text}
                    altText={ErrorPages[404].altText}
                    ctaText={ErrorPages[404].ctaText}
                    ctaDestination={ErrorPages[404].ctaDestination}
                  />
                </Route>
                <Route exact path="/error">
                  <ErrorPage
                    image={ErrorPages[500].image}
                    title={ErrorPages[500].title}
                    text={ErrorPages[500].text}
                    altText={ErrorPages[500].altText}
                    ctaText={ErrorPages[500].ctaText}
                    ctaDestination={ErrorPages[500].ctaDestination}
                  />
                </Route>
                <Suspense
                  fallback={
                    <StyledSpinnerContainer>
                      <Spinner />
                    </StyledSpinnerContainer>
                  }
                >
                  {(categoryDomain && (
                    <Route
                      exact
                      path={`/${categoryMapperKeys.find((key) => keyToCategoryNameMapper[key] === categoryDomain)}`}
                    >
                      <LowLevelInsights />
                    </Route>
                  )) || <Redirect to="/notfound" />}
                </Suspense>
              </Switch>
            </>
          )}
        </StyledApp>
      </AppContextProvider>
    </MoThemeProvider>
  );
}

export default App;
