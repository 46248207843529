import styled from 'styled-components';
import { IStyledProps } from '../../models/styledProps';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    overflow: hidden;
`;

export const StyledImageContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 815px;
    height: 380px;
    display: flex;
    justify-content: center;

    & > img {
        position: absolute;
        bottom: -13px;
        width: 815px;
    }
`;

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 380px); // 380px is the ImageContainer height which sits above this element
    width: 100%;
    background: ${(props: IStyledProps) => props.theme.colors.brandThemeLight100};
    padding: 50px 1rem 0;

    & > h1 {
        margin-bottom: 0.8rem;
    }

    & > span {
        margin-bottom: 1rem;
    }

    & > button {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
        & > span {
            width: 440px;
        }
    }
`;