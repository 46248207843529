import React, { useContext } from "react";
import { Text, Link } from "@movember/mo-gel";
import { useHistory } from "react-router-dom";
import { HighLevelComparisonGraph } from "../HighLevelComparisonGraph/HighLevelComparisonGraph";
import {
  StyledCard,
  StyledHighLevelInsightWrapper,
  StyledInsightHeading,
  StyledInsightPhrase,
  StyledInlineScore,
  StyledScoreTitle,
  StyledScore,
  StyledGraphWrap,
  StyledComparisonWrap,
  StyledComparisonWrapLow,
  StyledYourScoreWrap,
  StyledButtonWrapper,
  StyledDeepDive,
  StyledDeepDiveHeading,
  StyledGraphContainer,
} from "./HighLevelComparison.styled";
import { AppContext } from "../../AppContext";
import { ILowLevelBarStack, LowLevelGraph } from "../LowLevelGraph/LowLevelGraph";
import { keyToCategoryNameMapper } from "../../utils/categoryDomainMapper";

interface ISide {
  answers: number[];
  labels: string[];
}

interface IHighLevelComparison {
  id: string;
  label: string;
  phrase: string;
  slug: string;
  ctaTopic: string;
  graphsection_1?: ISide;
  graphsection_2?: ISide;
}

interface IHighLevelComparisonProps {
  Comparison: IHighLevelComparison;
}

export default function HighLevelComparison({ Comparison }: IHighLevelComparisonProps) {
  const { handleViewLowLevel, userData, highLevels } = useContext(AppContext);
  const history = useHistory();

  const handleClick = () => {
    const categoryName = keyToCategoryNameMapper[Comparison.slug];
    handleViewLowLevel(categoryName);
    history.push(`/${Comparison.slug}`);
  };

  const parseInsightPhrase = (
    phrase: string | undefined,
    score: number | null,
    answerLabel: string | null
  ): JSX.Element => {
    if (!phrase) {
      return <StyledInsightPhrase />;
    }

    if (answerLabel) phrase = phrase.replace("{answerLabel}", answerLabel);

    const parts = phrase.split("{manScore}");
    const manScore = score ? Math.round(score).toString() : "N/A";
    return (
      <StyledInsightPhrase tag="p" marginBottom=".6rem">
        {parts[0]}
        <StyledInlineScore>{manScore}</StyledInlineScore>
        {parts[1]}
      </StyledInsightPhrase>
    );
  };

  const manScore = userData && userData.scores![Comparison.id];
  const comparisonData = highLevels?.find((i) => i.id === Comparison.id);
  const manAnswer = Comparison.id === "LIBIDOQu2" ? userData![Comparison.id] : false;
  const manAnswerLabel =
    (Comparison.id === "LIBIDOQu2" && manAnswer !== false && manAnswer >= 0)
      ? Comparison && Comparison.graphsection_1!.answers.includes(manAnswer!)
        ? Comparison.graphsection_1!.labels[0]
        : Comparison.graphsection_2!.labels[0]
      : 'N/A';
  const manSectionId = 
    (Comparison.id === "LIBIDOQu2" && manAnswer !== false && manAnswer >= 0) ? (Comparison && Comparison.graphsection_1!.answers.includes(manAnswer!) ? 1 : 2) : false;

  const summaryScore = (): JSX.Element => {
    return (
      <>
        {parseInsightPhrase(Comparison.phrase, manScore, null)}
        <StyledGraphWrap>
          <StyledYourScoreWrap>
            <StyledScoreTitle>Your score</StyledScoreTitle>
            <StyledScore level="h2">{(manScore && manScore >= 0) ? Math.round(manScore).toString() : 'N/A'}</StyledScore>
          </StyledYourScoreWrap>
          <StyledGraphContainer>
            <StyledComparisonWrap>
              <HighLevelComparisonGraph
                manScore={manScore}
                otherMenScoreStart={comparisonData?.low ?? 0}
                otherMenScoreEnd={comparisonData?.high ?? 0}
              />
            </StyledComparisonWrap>
          </StyledGraphContainer>
        </StyledGraphWrap>
      </>
    );
  };

  const sexualDevices = (): JSX.Element => {
    const barStack: ILowLevelBarStack = {
      mansScore: manSectionId,
      segment1: {
        score: 100 - Math.round((comparisonData?.score ?? 0) * 100),
        labels: Comparison.graphsection_1!.labels
      },
      segment2: {
        score: Math.round((comparisonData?.score ?? 0) * 100),
        labels: Comparison.graphsection_2!.labels
      }
    }
    return (
      <>
        {parseInsightPhrase(Comparison.phrase, Math.round((1 - (comparisonData?.score ?? 0)) * 100), manAnswerLabel)}
        <StyledGraphWrap>
          <StyledYourScoreWrap>
            <StyledScoreTitle>Your answer</StyledScoreTitle>
            <StyledScore level="h2">{manAnswerLabel}</StyledScore>
          </StyledYourScoreWrap>
          <StyledGraphContainer>
              <StyledScoreTitle>Your comparison</StyledScoreTitle>
            <StyledComparisonWrapLow>
              <LowLevelGraph {...barStack} />
            </StyledComparisonWrapLow>
          </StyledGraphContainer>
        </StyledGraphWrap>
      </>
    );
  };

  return (
    <StyledCard tag="li" margin="0" shadow={null} borderAll={false} backgroundColor="transparent">
      <StyledHighLevelInsightWrapper>
        <StyledInsightHeading level="h3">{Comparison.label}</StyledInsightHeading>
        {Comparison.id === "LIBIDOQu2" ? sexualDevices() : summaryScore()}
      </StyledHighLevelInsightWrapper>
      <StyledDeepDive>
        <StyledDeepDiveHeading level="h5">Dive a little deeper into {Comparison.ctaTopic}</StyledDeepDiveHeading>
        <StyledButtonWrapper data-track-type="Explore insights" data-track-location={Comparison.slug}>
          <Text tag="p" marginBottom="0.7rem">
            Take a more detailed look at your experience and how it compares to other men
          </Text>
          <Link onClick={handleClick}>
            View detailed comparisons
          </Link>
        </StyledButtonWrapper>
      </StyledDeepDive>
    </StyledCard>
  );

}
