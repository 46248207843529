import React, {useContext} from 'react';
import { AppContext } from '../../AppContext';
import { SaveInsightsContainer, SaveInsightsButton, SaveInsightsMessage } from './SaveInsights.styled';


export const SaveInsights: React.FC<any> = () => {

  const { setShowSaveModal } = useContext(AppContext);
  
  return (
      <SaveInsightsContainer>
        <SaveInsightsButton primary onClick={() => setShowSaveModal(true)}>
          Save page
        </SaveInsightsButton>
        <SaveInsightsMessage>
          As you have only 30 days of access, you may wish to print or save this page.
        </SaveInsightsMessage>
      </SaveInsightsContainer>
  )

}
