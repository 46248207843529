import { Text } from "@movember/mo-gel";
import styled from "styled-components";
import { IStyledProps } from '../../models/styledProps';

export const ErrorContainer = styled.div`
    height: 100vh;
`;

export const ErrorContent = styled.div`
    max-width: ${(props: IStyledProps) => props.theme.breakpoints.lg};
    padding: 8rem 0 3.7rem 2.4rem;
    width: 100%;
    margin: 0 auto;
`

export const StyledSubHeadingContainer = styled.div`
    padding: 1rem 0;
`;

export const StyledSubHeading = styled(Text)`
    color: ${(props) => props.theme.colors.actionPrimary300};
    padding-bottom: 0.75rem;
`;

export const StyledMessageContainer = styled.div`
    padding-bottom: 2rem;
`
