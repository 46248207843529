import React, { useState, MouseEvent } from 'react';
import { StyledContainer, StyledImage, StyledTab, StyledTabContent, StyledTabs, StyledTextContainer, StyledHeading, StyledText, StyledButton } from './TabSelector.styled';
import { Text, Link } from '@movember/mo-gel';
import AboutBS from '../../assets/about-bs.png';
import Men from '../../assets/3men.jpg';
import MenSmiling from '../../assets/menwalkingsmiling.jpg';
import { useHistory } from 'react-router-dom';

export const TabSelector = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const history = useHistory();

    const handleClick = (event: MouseEvent) => {
        event.preventDefault();
        history.push("/compare");
    }

    const getStartedButton = () => <StyledButton secondary onClick={handleClick}>Get Started</StyledButton>;
    const inlineLink = (text: string, href: string) => <Link primary href={href} target="__blank">{text}</Link>;

    const tabData = [
        {
            tabTitle: "About BroSupPORT",
            content: {
                image: AboutBS,
                text: () => (
                    <StyledTextContainer>
                        <StyledHeading>
                            {`About\nBroSupPORT`}
                        </StyledHeading>
                        <StyledText tag="p">
                            It can be hard to know what’s normal to be experiencing after prostate cancer diagnosis and what’s not. And also – how are other men like you doing after diagnosis?
                        </StyledText>
                        <StyledText tag="p">
                            Designed with the input of men like you, BroSupPORT can help you to better understand problem areas after diagnosis and take appropriate action.
                        </StyledText>
                        <StyledText tag="p">
                            BroSupPORT is a collaboration between {inlineLink("Monash University", "https://www.monash.edu/")} and {inlineLink("Movember", "https://au.movember.com/")}, with funding support from the {inlineLink("Victorian Agency for Health Information (VAHI)", "https://vahi.vic.gov.au/")}.
                        </StyledText>
                        {getStartedButton()}
                    </StyledTextContainer>
                )
            }
        },
        {
            tabTitle: "Comparison Overview",
            content: {
                image: Men,
                text: () => (
                    <StyledTextContainer>
                        <StyledHeading>
                            {`Comparison Overview`}
                        </StyledHeading>
                        <StyledText tag="p">
                            The Comparison Overview can help identify how you are doing in different areas of bother and how your experience compares with what other men similar to you are likely to report.
                        </StyledText>
                        <StyledText tag="p">
                            You will learn how you scored in the survey with respect to different functions and what your score means. And simple visuals allow you to easily compare and understand how you’re doing in relation to other men like you.
                        </StyledText>
                        {getStartedButton()}
                    </StyledTextContainer>
                )
            }
        },
        {
            tabTitle: "Detailed Comparisons",
            content: {
                image: MenSmiling,
                text: () => (
                    <StyledTextContainer>
                    <StyledHeading>
                        {`Detailed Comparisons`}
                    </StyledHeading>
                    <StyledText tag="p">
                        Want to know more? Detailed Comparisons offer a deeper dive into each area of bother and compares your experience with that of other men similar to you. Along with the comparisons is the opportunity to explore information and resources that can help you take positive action.
                    </StyledText>
                    <StyledText tag="p">
                        Once you’ve taken this in, there is an opportunity to explore information and resources to help you take positive action. 
                    </StyledText>
                    {getStartedButton()}
                </StyledTextContainer>
                )
            }
        },
    ]

    return <StyledContainer id="learn_more">
        <StyledTabs>
            {
                tabData.map((data: any, index: number = 0) => (
                    <StyledTab key={index} data-testid={`tab-${index}`} isSelected={index === selectedIndex} onClick={() => setSelectedIndex(index)}>
                        <Text fontWeight="bold">{data.tabTitle}</Text>
                    </StyledTab>
                ))
            }
        </StyledTabs>
        <StyledTabContent>
            <StyledImage src={ tabData[selectedIndex].content.image } />
            { tabData[selectedIndex].content.text() }
        </StyledTabContent>
    </StyledContainer>
}