import styled from 'styled-components';
import { Text, Heading } from '@movember/mo-gel';

export const StyledContentContainer = styled.div`
  padding: 8rem 0 3.7rem;
  width: 100%;
  max-width: ${(props) => props.theme.breakpoints.lg};
  margin: 0 auto;
`;

export const StyledSubHeadingContainer = styled.div`
  padding: 1rem 0;
`;

export const StyledSubHeading = styled(Text)`
  color: ${(props) => props.theme.colors.actionPrimary300};
  text-transform: uppercase;
`;

export const StyledAttributeGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledTreatmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
`;

export const StyledAttributeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 10rem;
`;

export const StyledHeading = styled(Heading)`
  width: 75%;
  margin-bottom: 2rem;
`;