import styled from 'styled-components';
import { IStyledProps } from '../../models/styledProps';
import { Button, Text } from '@movember/mo-gel';

export const SaveInsightsContainer = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    align-items: center;
    border-top: 1px solid ${(props: IStyledProps) => props.theme.colors.actionPrimary700};
    border-bottom: 1px solid ${(props: IStyledProps) => props.theme.colors.actionPrimary700};
`

export const SaveInsightsButton = styled(Button)`
    width: 194px;
`

export const SaveInsightsMessage = styled(Text)`
    padding-left: 26px;
    width: 70%;
`
