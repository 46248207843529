import { DefaultTheme } from 'styled-components';

const colors = {
  white: '#ffffff',
  black: '#000000',
  brandThemeDark900: '#0c1e2c',
  brandThemeDark700: '#275b86',
  brandThemeDark500: '#3f88c5',
  brandThemeLight500: '#d7d6cb',
  brandThemeLight300: '#e2ded1',
  brandThemeLight100: '#f1efe9',
  actionPrimary700: '#2e6899',
  actionPrimary500: '#3e7eb3',
  actionPrimary300: '#4ba1e9',
  primaryFont: '#0c1e2c',
  accentFont: '#4ba1e9',

  error500: '#bf0000',
  // new
  error300: '#e62323',

  // warning colors: orange and yellow
  warning500: '#f26800',
  // originally warning 1
  warning300: '#ff8200',

  // darkest/worse (500) to lightest/best (300)
  success500: '#76b33b',
  success300: '#8fd848'
};

const breakpoints = {
  xxs: '320px',
  xs: '520px',
  sm: '768px',
  md: '1024px',
  lg: '1280px',
  xl: '1760px'
};

export const TrueNorthLight: DefaultTheme = {
  dark: false,
  name: 'True North Light',
  defaultFontFamily: 'Overpass',
  colors,
  breakpoints,
  containerMaxWidth: '1080px'
};
