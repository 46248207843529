import styled from 'styled-components';
import { IStyledProps } from '../../models/styledProps';

export const StyledSignpostOAContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeLight500};
  border-bottom: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeLight500};
`;

export const StyledContentContainer = styled.div`
  padding: 7.5rem 7.5rem 7rem 0;
`;

export const StyledTextContainer = styled.div`
  padding: 1rem 1.5rem 0 0;
`;