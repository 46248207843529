import React from 'react';
import { Text } from '@movember/mo-gel';
import { 
  StyledContainer,
  StyledHeadingContainer,
  StyledTextContainer,
  StyledHeading,
  StyledLink,
  StyledPhone
} from './TipsAndCautions.styled';

export default function TipsAndCautions() {
  const inlineLink = (text: string, href: string) => <StyledLink primary href={href} target="__blank">{text}</StyledLink>;

  return (
    <StyledContainer>
      <StyledHeadingContainer>
        <StyledHeading level="h4">About this data</StyledHeading>
      </StyledHeadingContainer>
      <StyledTextContainer>
        <Text><strong>Who are the men 'similar to you'?</strong></Text>
        <Text tag="p" marginBottom="1.6rem">The BroSupPORT comparisons are powered by data from over 11,000 men in the Prostate Cancer Outcomes Registry of Victoria (PCOR-VIC), collected 12 months after treatment for localised prostate cancer.</Text>
        <Text tag="p" marginBottom="1.6rem">The comparisons shown in the graphs are based on your survey responses and what other men similar to you in age, treatment and cancer severity are likely to report. Cancer severity has been defined as the likelihood of cancer growing and spreading according to the National Comprehensive Cancer Network (NCCN). This risk is calculated using a combination of your PSA level, Gleason score and results of your digital rectal examination (DRE).</Text>
        <Text><strong>Are some problems more commonly reported than others?</strong></Text>
        <Text tag="p" marginBottom="1.6rem">Across all men who have had treatment for prostate cancer in Victoria, sexual issues are 5 times more likely to be reported than urinary issues, and urinary issues are 2 times more likely to be reported than bowel issues.</Text>
        <Text><strong>Anxious or worried about how you're doing?</strong></Text>
        <Text tag="p" marginBottom="1.6rem">Please reach out to your GP, cancer specialist, the <StyledPhone>13 11 20</StyledPhone> nurses at {inlineLink("Cancer Council Victoria", "https://www.cancervic.org.au/")} or the mental health team at {inlineLink("Beyond Blue", "https://www.beyondblue.org.au/")} on <StyledPhone>1300 22 44 36</StyledPhone>.</Text>
      </StyledTextContainer>
    </StyledContainer>
  );
}
