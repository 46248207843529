import React from "react";
import {
  StyledPageHeaderContent,
  StyledImage,
  StyledImageContainer,
  StyledPageHeaderContentContainer,
  StyledImageContentContainer,
  StyledHeading,
  StyledButton,
  StyledButtonContainer,
  StyledPageHeaderBackgroundContainer,
  StyledSubHeading
} from "./PageHeader.styled";
import { Text } from "@movember/mo-gel";
import headerImage from "../../assets/bs_landing_page.jpg";
import { useHistory } from "react-router-dom";

export const PageHeader: React.FC<any> = () => {
  const history = useHistory();

  const handleLearnMore = () => {
    const tabSelectorDiv = document.getElementById("learn_more");
    tabSelectorDiv && tabSelectorDiv.scrollIntoView();
  }

  return (
    <>
      <StyledPageHeaderBackgroundContainer>
        <StyledPageHeaderContentContainer>
          <StyledPageHeaderContent>
            <Text>Welcome to</Text>
            <StyledHeading level="h1">BroSupPORT</StyledHeading>
            <StyledSubHeading level="h5" fontWeight="500" marginBottom="2.2rem">
              Gain a deeper understanding of how you’re doing after a<br/>
              prostate cancer diagnosis and how your experience compares <br/>
              with that of other men like you.
            </StyledSubHeading>
            <StyledButtonContainer>
              <StyledButton primary onClick={() => history.push("/compare")}>
                Get Started
              </StyledButton>
              <StyledButton secondary onClick={handleLearnMore}>Learn More</StyledButton>
            </StyledButtonContainer>
          </StyledPageHeaderContent>
        </StyledPageHeaderContentContainer>
      </StyledPageHeaderBackgroundContainer>
      <StyledImageContainer>
        <StyledImageContentContainer>
          <StyledImage src={headerImage} alt="two men smiling"></StyledImage>
        </StyledImageContentContainer>
      </StyledImageContainer>
    </>
  );
};
